import { render, staticRenderFns } from "./distributorCreate.vue?vue&type=template&id=c5cf6490&scoped=true&"
import script from "./distributorCreate.vue?vue&type=script&lang=js&"
export * from "./distributorCreate.vue?vue&type=script&lang=js&"
import style0 from "@/styles/css/personCreate.css?vue&type=style&index=0&id=c5cf6490&lang=css&scoped=true&"
import style1 from "@/styles/scss/personCreate.scss?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5cf6490",
  null
  
)

export default component.exports